<template>
    <div class="page-index">
        <div style="background-color: white;text-align: center;padding: 60px">
            <el-row>
                <el-col :span="12">
                    <span style="font-size: 20px;font-weight: bold">累计受惠企业数量</span>
                    <span style="margin-left:5px;padding: 5px;background-color: #e0eefd;color: #0948A5;font-size: 14px">单位：件</span>
                    <div style="padding: 10px">
                        <div style="font-weight: bold;font-size: 40px;color: #0948A5">{{ value1 }}</div>
                        <div style="font-size: 14px">累计用户</div>
                    </div>

                    <el-row>
                        <el-col :span="12">
                            <div style="padding: 10px">
                                <div style="font-weight: bold;font-size: 28px;color: #565656">{{ value2 }}</div>
                                <div style="font-size: 14px">累计案件数</div>
                            </div>
                        </el-col>
                        <el-col :span="12" style="border-left: 1px solid #CFE7FD;">
                            <div style="padding: 10px">
                                <div style="font-weight: bold;font-size: 28px;color: #565656">{{ value3 }}</div>
                                <div style="font-size: 14px">结案数量</div>
                            </div>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :span="12">
                    <span style="font-size: 20px;font-weight: bold">累计金额</span>
                    <span style="margin-left:5px;padding: 5px;background-color: #FFF8E2;color: #FCAD54;font-size: 14px">单位：万元</span>

                    <div style="padding: 10px">
                        <div style="font-weight: bold;font-size: 40px;color: #FCAD54">{{ value4 }}</div>
                        <div style="font-size: 14px">累计报案金额</div>
                    </div>
                    <el-row>
                        <el-col :span="12">
                            <div style="padding: 10px">
                                <div style="font-weight: bold;font-size: 28px;color: #565656">{{ value5 }}</div>
                                <div style="font-size: 14px">承诺还款金额</div>
                            </div>
                        </el-col>
                        <el-col :span="12" style="border-left: 1px solid #CFE7FD;">
                            <div style="padding: 10px">
                                <div style="font-weight: bold;font-size: 28px;color: #565656">{{ value6 }}</div>
                                <div style="font-size: 14px">到账金额</div>
                            </div>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
        </div>

        <div style="margin-top:30px;font-size: 20px;font-weight: bold">调解申请</div>
        <el-image :src="fileUrl+'image/index/index_sqtj.png'" @click="onClick({type:1, path: 'login'})"/>

        <div style="margin-top:30px;margin-bottom:10px;font-size: 20px;font-weight: bold">服务领域</div>
        <el-row :gutter="20" type="flex" justify="center">
            <el-col :span="8" v-for="(item,index) in imageList" :key="index">
                <div style="background: url('https://www.zhizhixy.com/files/zhizhi/image/index/index_bg_1.png'); height: 400px;" @click="onClick(item)">
                    <div style="display: flex;justify-content: center;padding-top: 60px">
                        <el-image style="width: 100px;height: 100px" :src="item.img"/>
                    </div>
                    <div style="padding: 60px;line-height: 30px">
                        <div style="text-align: center;font-weight: bold;margin-bottom: 20px">{{item.caption}}</div>
                        <div>{{item.remark1}}</div>
                        <div>{{item.remark2}}</div>
                    </div>
                </div>
            </el-col>
        </el-row>

        <div style="margin-top:30px;margin-bottom:10px;font-size: 20px;font-weight: bold">成功案例</div>
        <el-row :gutter="20">
            <el-col :span="12" style="height: 300px;">
                <el-image :src="fileUrl + 'image/index/index_new.png'"/>
            </el-col>
            <el-col :span="12">
                <div class="bsy-card" style="height: 292px;">
                    <el-tabs v-model="activeName">
                        <el-tab-pane label="" name="001">
                            <el-row v-for="(item,index) in articleList2" :key="index" style="height: 30px;line-height: 30px">
                                <el-col :span="19">
                                    <el-link type="primary" icon="el-icon-link" @click="onClickNews(item)">{{item.Name}}</el-link>
                                </el-col>
                                <el-col :span="5" style="color: #999999;">
                                    <div style="font-size: large;margin-top: 5px;text-align: right">{{ item.BizDateText }}</div>
                                </el-col>
                            </el-row>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </el-col>
        </el-row>

        <el-row :gutter="20">
            <el-col :span="12" style="height: 300px;">
                <div style="margin-top:30px;margin-bottom:10px;font-size: 20px;font-weight: bold">信用动态</div>
                <div class="bsy-card" style="height: 260px;">
                    <el-tabs v-model="activeName">
                        <el-tab-pane label="" name="001">
                            <el-row v-for="(item,index) in articleList1" :key="index" style="height: 30px;line-height: 30px">
                                <el-col :span="19">
                                    <el-link type="primary" icon="el-icon-link" @click="onClickNews(item)">{{item.Name}}</el-link>
                                </el-col>
                                <el-col :span="5" style="color: #999999;">
                                    <div style="font-size: large;margin-top: 5px;text-align: right">{{ item.BizDateText }}</div>
                                </el-col>
                            </el-row>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </el-col>
            <el-col :span="12" style="height: 300px;">
                <div style="margin-top:30px;margin-bottom:10px;font-size: 20px;font-weight: bold">政策法规</div>
                <div class="bsy-card" style="height: 260px;">
                    <el-tabs v-model="activeName">
                        <el-tab-pane label="" name="001">
                            <el-row v-for="(item,index) in articleList3" :key="index" style="height: 30px;line-height: 30px">
                                <el-col :span="19">
                                    <el-link type="primary" icon="el-icon-link" @click="onClickNews(item)">{{item.Name}}</el-link>
                                </el-col>
                                <el-col :span="5" style="color: #999999;">
                                    <div style="font-size: large;margin-top: 5px;text-align: right">{{ item.BizDateText }}</div>
                                </el-col>
                            </el-row>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </el-col>
        </el-row>
                <div style="height: 80px"/>

<!--        <el-row :gutter="20">-->
<!--            <el-col :span="12" style="height: 300px;">-->
<!--                <el-carousel trigger="click" height="300px">-->
<!--                    <el-carousel-item v-for="(item,index) in bannerList" :key="index">-->
<!--                        <el-image :src="item.ImageUrl"/>-->
<!--                    </el-carousel-item>-->
<!--                </el-carousel>-->
<!--            </el-col>-->
<!--            <el-col :span="12">-->
<!--                <div class="bsy-card" style="height: 260px;">-->
<!--                    <el-tabs v-model="activeName">-->
<!--                        <el-tab-pane label="成功案例" name="001">-->
<!--                            <el-row v-for="(item,index) in articleList2" :key="index" style="height: 30px;line-height: 30px">-->
<!--                                <el-col :span="19">-->
<!--                                    <el-link type="primary" icon="el-icon-link" @click="onClickNews(item)">{{item.Name}}</el-link>-->
<!--                                </el-col>-->
<!--                                <el-col :span="5" style="color: #999999;">-->
<!--                                    <div style="font-size: large;margin-top: 5px;text-align: right">{{ item.BizDateText }}</div>-->
<!--                                </el-col>-->
<!--                            </el-row>-->
<!--                        </el-tab-pane>-->
<!--                    </el-tabs>-->
<!--                </div>-->
<!--            </el-col>-->
<!--        </el-row>-->

<!--        <div style="height: 20px"/>-->

<!--        <el-row :gutter="20" type="flex" justify="center">-->
<!--            <el-col :span="6" v-for="(item,index) in buttonList" :key="index">-->
<!--                <div class="bsy-card">-->
<!--                    <div style="cursor: pointer;text-align: center" @click="onClick(item)">-->
<!--                        {{item.caption}}<i class="el-icon-arrow-right"/>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </el-col>-->
<!--        </el-row>-->

<!--        <div style="height: 20px"/>-->

    </div>
</template>

<script>
export default {
    name: 'index',
    components: {},
    computed: {

    },
    data() {
        return {
            fileUrl: this.$Config.api.fileUrl,
            activeName: '001',
            bannerList: [],
            articleList1: [],
            articleList2: [],
            articleList3: [],

            buttonList: [
                { type: 0, caption: '信用查询', url: 'https://www.creditchina.gov.cn/xinyongfuwu/tongyishehuixinyongdaimachaxunzhuanlan/' },
                { type: 1, caption: '政策法规', path: 'policy-list' },
                { type: 1, caption: '服务范围', path: 'scope-list' },
                { type: 1, caption: '下载中心', path: 'download' },
            ],
            imageList: [
                { type: 1, caption: '信用调解', remark1: '', remark2: '', img: this.$Config.api.fileUrl + 'image/index/icon1.png', path: 'login' },
                { type: 1, caption: '下载中心', remark1: '', remark2: '', img: this.$Config.api.fileUrl + 'image/index/icon2.png', path: 'download' },
                // { type: 1, caption: '信用管理师', remark1: '这里对信用调解的详细描述', remark2: '信用调解的描述。', img: this.$Config.api.fileUrl + 'image/index/icon3.png', path: 'scope-list' },
                { type: 0, caption: '信用管理师', remark1: '', remark2: '',img: this.$Config.api.fileUrl + 'image/index/icon2.png', url: 'https://gdcmma-101.365hjy.com/' },
            ],

            value1: 0, //累计数量=注册的企业数
            value2: 0, //调解数量=所有申请单的数量
            value3: 0, //结案数量=已经结案的数量
            value4: 0, //累计金额=所有申请单的金额
            value5: 0, //承诺金额=已还款金额*120%
            value6: 0,  //到账金额=已还款金额
        }
    },
    mounted() {
        //轮播图
        this.$API.GetResourceList('Banner', {Sort: 'Seq, CreatedOn desc'}, resp =>{
            this.bannerList = resp.Data.Items
        })

        //文章-信用动态
        this.$API.GetResourceList('Article', { Filter: "GroupID==3 and Status==\"C\"", Size: 6, Sort: 'Seq,BizDate desc' }, resp =>{
            this.articleList1 = resp.Data.Items
        })

        //文章-成功案例
        this.$API.GetResourceList('Article', { Filter: "GroupID==2 and Status==\"C\"", Size: 6, Sort: 'Seq,BizDate desc' }, resp =>{
            this.articleList2 = resp.Data.Items
        })

        //文章-政策法规
        this.$API.GetResourceList('Article', { Filter: "GroupID==1 and Status==\"C\"", Size: 6, Sort: 'Seq,BizDate desc' }, resp =>{
            this.articleList3 = resp.Data.Items
        })

        this.$API.PostService('Report/IndexData/Anonymous', { }, resp =>{
            this.value1 = resp.Data.EnterpriseQty //累计数量=注册的企业数
            this.value2 = resp.Data.ResponseQty //调解数量=所有申请单的数量
            this.value3 = resp.Data.ClosedQty //结案数量=已经结案的数量
            this.value4 = resp.Data.ResponseAmount //累计金额=所有申请单的金额
            this.value5 = resp.Data.PromisedAmount //承诺金额=已还款金额*120%
            this.value6 = resp.Data.PaidAmount //到账金额=已还款金额
        }, false)
    },
    methods: {
        onResquest(){
            this.$router.push( { name: 'register'} )
        },
        onOpenWindow(url, title){
            // this.$Util.OpenWindow(url, title)
            window.open(url)
        },
        onClick(data){
            if(data.type === 0){
                window.open(data.url)
            }else{
                this.$router.push( { name: data.path } )
            }
        },
        onClickNews(data){
            // this.$router.push( { name: data.path, query: {} } )
            this.$router.push({ name: "news", params: { data: data } })
        }
    }
}
</script>

<style lang="scss">
::-webkit-scrollbar {
    width: 0 !important;
}
::-webkit-scrollbar {
    width: 0 !important;height: 0;
}

.page-index{
    //直接在index父项定义
    //margin: 0 auto;
    //width:1200px;

    .bsy-card{
        box-shadow: 0 0 5px #c1c1c1;
        //background-color: #FFFFFF;
        padding: 20px;
    }

    .el-tabs__item{
        font-size: larger;
        font-weight: bold;
        margin-top: -10px;
    }
    .el-tabs__item.is-active {
        color: #0948A5;
    }
    .el-tabs__active-bar{
        background-color: #0948A5;
    }
    .el-link--inner{
        font-size: 16px;
    }
    .bsy-border-radius{
        box-shadow: 0 0 10px #c1c1c1;
    }
}
</style>
