<template>
    <div class="page-policy-list">
        <el-breadcrumb class="breadcrumb-class">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>政策法规列表</el-breadcrumb-item>
        </el-breadcrumb>

        <div class="policy-card-list" v-for="(item,index) in list" :key="index" >
            <div style="display: flex;">
                <el-image style="width: 400px; height: 200px" :src="item.ImageUrl" />
                <div style="padding: 30px">
                    <el-link style="padding-bottom: 50px;font-size: 30px;font-weight: bold" @click="onClickNews(item)">{{item.Name}}</el-link>
                    <div>时间：{{item.BizDateText}}</div>
                    <div>作者：{{item.Author}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'policy-list',
    data() {
        return {
            list: []
        }
    },
    mounted() {
        this.$API.GetResourceList('Article', { Filter: "GroupID==1 and Status==\"C\"", Sort: 'Seq' }, resp =>{
            this.list = resp.Data.Items
        })
    },
    methods: {
        onClickNews(data){
            this.$router.push({ name: "news", params: { data: data } })
            // this.$Util.NavigateTo('/news', {data: data})
        }
    }
}
</script>

<style lang="scss">
::-webkit-scrollbar {
    width: 0 !important;
}
::-webkit-scrollbar {
    width: 0 !important;height: 0;
}

.page-policy-list{
    margin: 0 auto;
    width:1200px;

    background-color: #FFFFFF;
    padding: 20px;

    .breadcrumb-class{
        //margin-top: 20px;
        margin-bottom: 30px;
        font-size: 20px
    }

    .policy-card-list{
        margin-bottom: 20px;

        box-shadow: 0 0 5px #c1c1c1;
    }
}
</style>
