<template>
    <div class="page-policy">
        <el-breadcrumb class="breadcrumb-class">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>政策法规</el-breadcrumb-item>
        </el-breadcrumb>
        <el-image style="width: 100px; height: 100px" src="https://www.zhizhikj.com/uploads/20211109/1636450880395702.png" />
    </div>
</template>

<script>
export default {
    //政策法规
    name: 'policy',
    data() {
        return {

        }
    },
    mounted() {

    },
    methods: {

    }
}
</script>

<style lang="scss">
::-webkit-scrollbar {
    width: 0 !important;
}
::-webkit-scrollbar {
    width: 0 !important;height: 0;
}

.page-policy{
    margin: 0 auto;
    width:1200px;

    background-color: #FFFFFF;
    padding: 20px;

    .breadcrumb-class{
        //margin-top: 20px;
        margin-bottom: 30px;
        font-size: 20px
    }
}
</style>
