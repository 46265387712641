<template>
        <div class="register-container">
<!--            <el-image src="https://www.fszjfy.gov.cn/res/t3/fsfy/img/fs_logo.png" style="position: fixed;top: 80px;left: 100px"/>-->
            <div class="register-weaper animated bounceInDown">
                <div class="register-border">
                    <div class="register-main">
                        <h4 class="register-title">用户注册</h4>

                        <el-form class="register-form" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
                            <el-form-item label="手机号码" prop="Phone">
                                <el-input v-model="ruleForm.Phone" placeholder="请输入手机号码">
                                    <i slot="prefix" class="el-icon-mobile-phone" />
                                    <el-button style="background-color: #409eff;color: white;width: 125px" slot="append" :disabled="disabledBtn" @click="GetSmsCode">{{buttonText}}</el-button>
                                </el-input>
                            </el-form-item>
                            <el-form-item label="验证码" prop="Code">
                                <el-input v-model="ruleForm.Code" placeholder="请输入手机验证码">
                                    <i slot="prefix" class="el-icon-chat-line-square" />
                                </el-input>
                            </el-form-item>
                            <el-radio-group v-model="ClientID" style="margin: 10px">
                                <el-radio label="Enterprise">企业机构</el-radio>
<!--                                <el-radio label="Mediator">调解机构</el-radio>-->
                                <el-radio label="Community">社会组织</el-radio>
                            </el-radio-group>
                            <el-form-item style="margin-top: 30px">
                                <el-button style="width: 125px;" type="primary" @click="submitForm('ruleForm')">立即注册</el-button>
                                <el-link type="info" style="float: right" @click="onLogin">已有账号，直接登录 > </el-link>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
            </div>
            <div class="bottom-fixed">
                <div class="bottom-version">{{config.title}} © copyright © 2022</div>
            </div>

            <reset ref="reset" @onConfirmReset="onConfirmReset"/>
        </div>
</template>

<script>
    import Reset from "@/page/login/reset";
    export default {
        components: {Reset},
        data() {
            var validatorPhone = function (rule, value, callback) {
                if (value === '') {
                    callback(new Error('手机号不能为空'))
                } else if(!(/^1[3456789]\d{9}$/.test(value))){
                    callback(new Error('手机号格式错误'))
                } else {
                    callback()
                }
            }
            return {
                config: this.$Config.login,
                ClientID: 'Enterprise', //EnterpriseUser、AdminUser、MediatorUser、Community
                ruleForm: {
                    Phone: '',//
                    Code: '', //验证码

                    Password: '',//
                    ConfirmPassword: '',//
                },
                rules: {
                    Code: [
                        { required: true, message: '请输入短信验证码', trigger: 'blur' },
                        { min: 6, max: 6, message: '请输入正确的6位数短信验证码', trigger: 'blur' },
                    ],
                    Phone: [
                        { required: true, validator: validatorPhone, trigger: 'blur' }
                    ]
                },

                disabledBtn: false,
                totalTime: 60,
                buttonText: "发送验证码"
            }
        },
        mounted(){

        },
        methods: {
            onLogin(){
                this.$router.push( { name: 'login'} )
            },

            //发送短信
            GetSmsCode(){
                let phoneNum = this.ruleForm.Phone
                let reg = 11 && /^1[3456789]\d{9}$/;

                if(this.$Util.IsNull(phoneNum)){
                    this.$Util.MsgBox('请输入手机号码', -1)
                }
                else if(!reg.test(phoneNum)){
                    this.$Util.MsgBox('手机格式不正确', -1)
                }
                else{
                    if(this.totalTime === 60){
                        this.disabledBtn = true
                        this.onStartTimer()

                        let param = {
                            Phone: this.ruleForm.Phone,
                            ClientID: this.$Config.api.clientID + this.ClientID,
                        }
                        // 发送注册验证码
                        this.$API.SendRegisterSms(this.ClientID + "User", param, resp => {
                            this.$Util.MsgBox(resp.Message, resp.Status)
                        })
                    }
                }
            },

            //计时
            onStartTimer() {
                let clock =  window.setInterval(() => {
                    this.buttonText = this.totalTime + 's后重新发送';
                    this.totalTime --;
                    if(this.totalTime < 0){
                        this.disabledBtn = false

                        this.totalTime = 60;
                        this.buttonText = "重新发送验证码";
                        window.clearInterval(clock);
                    }
                }, 1000);
            },

            submitForm(formName) {
                let _this = this
                _this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let param = {
                            Phone: _this.ruleForm.Phone,
                            Code: _this.ruleForm.Code,
                            ClientID: _this.$Config.api.clientID + _this.ClientID,
                        }
                        // 验证码注册用户
                        _this.$API.SmsRegister(_this.ClientID + "User", param, resp =>{
                            let menu = _this.$Util.loginStore(_this.ClientID, resp.Data)

                            // 重置密码
                            _this.$refs.reset.onShowDialog(param.ClientID, _this.ClientID + "User", _this.ruleForm.Phone, '设置密码', menu)
                        })
                    } else {
                        return false;
                    }
                });
            },

            onConfirmReset(menuPath){
                if(!this.$Util.IsNull(menuPath)){
                    this.$router.replace(menuPath)
                }
            }
        }
    }
</script>

<style lang="scss">
::-webkit-scrollbar {
    width: 0 !important;
}
::-webkit-scrollbar {
    width: 0 !important;height: 0;
}

.register-container {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    background: url("https://erp.miaosou.club/files/zhizhi/image/login_bg.png");
    background-size: cover;
}
.register-weaper {
    margin: 0 auto;
    width: 888px;
    z-index: 100;
    box-shadow: -4px 5px 10px rgba(0, 0, 0, 0.4);
    .el-input-group__append {
        border: none;
    }
}

.register-left,
.register-border {
    position: relative;
    min-height: 500px;
    align-items: center;
    display: flex;
}
.register-left {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    justify-content: center;
    flex-direction: column;
    background-color: #409EFF;
    color: #fff;
    float: left;
    width: 50%;
    position: relative;
}
.register-left .img {
    width: 140px;
}
.register-time {
    position: absolute;
    left: 25px;
    top: 25px;
    width: 100%;
    //color: #fff;
    color: #76838f;
    font-weight: 200;
    opacity: 0.9;
    font-size: 18px;
    overflow: hidden;
}
.register-left .title {
    margin-top: 60px;
    text-align: center;
    color: #fff;
    font-weight: 300;
    letter-spacing: 2px;
    font-size: 25px;
}

.register-border {
    border-left: none;
    border-radius: 5px;
    color: #fff;
    background-color: #fff;
    //width: 50%;
    width: 100%;
    float: left;
    box-sizing: border-box;
}
.register-main {
    margin: 0 auto;
    width: 65%;
    box-sizing: border-box;
}
.register-main > h3 {
    margin-bottom: 20px;
}
.register-main > p {
    color: #76838f;
}
.register-title {
    color: #333;
    margin-bottom: 40px;
    font-weight: 500;
    font-size: 22px;
    text-align: center;
    letter-spacing: 4px;
}
.register-menu {
    margin-top: 40px;
    width: 100%;
    text-align: center;
    a {
        color: #999;
        font-size: 12px;
        margin: 0px 8px;
    }
}
.register-submit {
    width: 100%;
    height: 45px;
    border: 1px solid #409EFF;
    background: none;
    font-size: 18px;
    letter-spacing: 2px;
    font-weight: 300;
    color: #409EFF;
    cursor: pointer;
    margin-top: 30px;
    font-family: "neo";
    transition: 0.25s;
}
.register-form {
    margin: 10px 0;
    i {
        color: #333;
    }
    .el-form-item__content {
        width: 100%;
    }
    .el-form-item {
        margin-bottom: 12px;
    }
    .el-input {
        input {
            padding-bottom: 10px;
            text-indent: 5px;
            background: transparent;
            border: none;
            border-radius: 0;
            color: #333;
            border-bottom: 1px solid rgb(235, 237, 242);
        }
        .el-input__prefix {
            i {
                padding: 0 5px;
                font-size: 16px !important;
            }
        }
    }
}
.register-code {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 0 0 0 10px;
}
.register-code-img {
    margin-top: 2px;
    width: 100px;
    height: 38px;
    background-color: #fdfdfd;
    border: 1px solid #f0f0f0;
    color: #333;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 5px;
    line-height: 38px;
    text-indent: 5px;
    text-align: center;
}

.bottom-fixed{
    //font-weight: bold;
    color: white;
    font-size: medium;
    z-index: 100;
    width: 100%;
    position: fixed;
    bottom:1%;
    .bottom-version{
        text-align: center;
    }
}

@-webkit-keyframes animate-cloud {
    from {
        background-position: 9999px 100%;
    }
    to {
        background-position: 0 100%;
    }
}
@-moz-keyframes animate-cloud {
    from {
        background-position: 9999px 100%;
    }
    to {
        background-position: 0 100%;
    }
}
@-ms-keyframes animate-cloud {
    from {
        background-position: 9999px 100%;
    }
    to {
        background-position: 0 100%;
    }
}
@-o-keyframes animate-cloud {
    from {
        background-position: 9999px 100%;
    }
    to {
        background-position: 0 100%;
    }
}

</style>
