<template>
    <div class="page-news">
        <el-breadcrumb class="breadcrumb-class">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>{{ dataModel.Group.Name }}</el-breadcrumb-item>
        </el-breadcrumb>

        <div class="page-class">
            <div class="page-box">
                <h1 style="text-align: center">{{dataModel.Name}}</h1>
                <h5 style="text-align: right;margin-top: -20px;margin-bottom: 40px">发布时间：{{dataModel.ModifiedOn}} 作者： {{dataModel.Author}}</h5>
                <p v-html="dataModel.Content" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'news',
    data() {
        return {
            articleID: 0,
            entity: "Article",
            dataModel: {
                Group: {Name: ''},
                ID: 0,
                BizDateText: "",
                Author: "",
                Content: "",
            }
        }
    },
    mounted() {
        let srcData = this.$route.params.data
        if (!this.$Util.IsNull(srcData)) {
            this.articleID = srcData.ID
            this.onGetModel()
        }else{
            this.$router.replace("/")
        }
    },
    methods: {
        onGetModel(){
            this.$API.GetResourceModel(this.entity, this.articleID, resp => {
                this.dataModel = resp.Data
            })
        }
    }
}
</script>

<style lang="scss">
::-webkit-scrollbar {
    width: 0 !important;
}
::-webkit-scrollbar {
    width: 0 !important;height: 0;
}

.page-news{
    margin: 0 auto;
    width:1200px;

    background-color: #FFFFFF;
    padding: 20px;

    .breadcrumb-class{
        //margin-top: 20px;
        margin-bottom: 30px;
        font-size: 20px
    }

    .page-class,.page-box{
        width: 1200px;
        padding: 50px 0 0 60px;
        background: #fff;
        border-radius: 0 20px 0 0;
    }
    .page-box{
        padding: 0 60px 40px 0;
    }
    .page-class{
        position: relative;
        filter: drop-shadow(0px 0px 15px #bbb);
    }
    .page-class:before{
        content: '';
        display: block;
        position: absolute;
        right:-60px;
        top: 0px;
        width: 60px;
        height: 71px;
        background: linear-gradient(42deg, #ddd 30%, rgba(0,0,0,0) 40%);
    }
}
</style>
