<template>
    <div class="page-about">
        <el-breadcrumb class="breadcrumb-class">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>关于我们</el-breadcrumb-item>
        </el-breadcrumb>

        <div style="display: flex;">
            <div style="margin: 15px">
                <div style="font-size: 30px;font-weight: bold">广东省信用管理师协会</div>
            </div>
        </div>
        <p style="font-size: 20px;">广东省信用管理师协会（以下简称“协会”）是由暨南大学金融工程研究所、深圳市公共信用中心、广东省信用担保协会、广东中天弘信用管理有限公司等11家单位于2015年7月经省政府相关职能部门（粤民函〔2015〕1145号）批准正式成立，注册地在广州市。是目前全国首家省级信用管理师职业资格培训、学习交流及咨询服务的公共服务平台，是广东省信用人才培养的重要基地。2017年5月，经佛山市禅城区信用办、禅城区人才办和佛山市禅城区人力资源和社会保障局联合引进，省民政厅批准，协会正式从广州市迁址佛山市；2017年8月，经佛山市禅城区人力资源和社会保障局批准建立人才服务联合工作站；2017年11月，经中共广东省社会组织委员会批准成立党支部；2018年，被广东省民政厅评定为3A级专业社团；2021年入选国家工信部中小企业志愿服务工作站。
        </p>
        <el-row style="font-size: 20px;font-weight: bold;">
            <el-col :span="14">
                <div style="line-height: 45px;margin-top: 30px">
                    <div>知之信用管理（广东）有限公司</div>
                    <div>http://www.zhizhikj.com</div>
                    <!-- <div>E-mail :  kb110000@163.com</div> -->
                    <div>佛山市南海区桂城街道南平西路广东夏西国际橡塑城一期5号楼20048</div>
                    <div>电话：0757-83312856</div>
                </div>
            </el-col>
            <el-col :span="10">
                <el-image style="width: 200px; height: 200px;margin-top: 20px" :src="qrcode" />
            </el-col>
        </el-row>

        <div style="margin-top: 20px">
            <div id="map" style="width:888px;height:300px;"></div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'about',
    data() {
        return {
            config: this.$Config.login,
            qrcode: require('@/assets/images/index/index_qrcode.jpg')
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            let address = "";
            let that = this;
            if(this.location === undefined){
                var center = new qq.maps.LatLng(23.048641, 113.160379);
            }else{
                var center = new qq.maps.LatLng(this.location.lat, this.location.lng);
            }
            var map = new qq.maps.Map(document.getElementById('map'), {
                center: center,
                zoom: 15,
                // disableDefaultUI: true
            });

            var marker = new qq.maps.Marker({
                position: center,
                map: map,
                dragDirection:true,
            });

            var infoWin = new qq.maps.InfoWindow({
                map: map
            });
            var geocoder = new qq.maps.Geocoder({
                complete: function(res) {
                    console.log('44' , res );
                    address = res.detail.nearPois[0].name;
                }
            });

            qq.maps.event.addListener(map, "click", function(event) {
                marker.setMap(null);
            });
            qq.maps.event.addListener(map, "click", function(event) {
                let result = {
                    status: 0,
                    result: {
                        location: {
                            lng: event.latLng.getLng(),
                            lat: event.latLng.getLat()
                        }
                    }
                };
                qq.maps.event.addListener(map, "click", function(event) {
                    marker.setMap(null);
                });
                var marker = new qq.maps.Marker({
                    position: event.latLng,
                    map: map
                });
            })

            qq.maps.event.addListener(map, "click", function(event) {
                // console.log('位置' , event )
                that.longitude = event.latLng.getLat();
                that.latitude = event.latLng.getLng();
                // console.log(event);
                let lat = new qq.maps.LatLng(that.longitude, that.latitude);
                geocoder.getAddress(lat);
                that.parsingAddress(that.longitude ,  that.latitude );   // 解析地址
                setTimeout(() => {
                    infoWin.open();
                    infoWin.setContent(
                        '<div style="text-align:center;white-space:nowrap;">' +
                        address +
                        "</div>"
                    );
                    infoWin.setPosition(event.latLng);
                }, 200);

            });
        },
    }
}
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
    width: 0 !important;
}
::-webkit-scrollbar {
    width: 0 !important;height: 0;
}

.page-about{
    margin: 0 auto;
    width:1200px;

    background-color: #FFFFFF;
    padding: 20px;

    .breadcrumb-class{
        //margin-top: 20px;
        margin-bottom: 30px;
        font-size: 20px
    }
}
</style>
