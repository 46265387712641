<template>
    <div class="page-download">
        <el-breadcrumb class="breadcrumb-class">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>下载中心</el-breadcrumb-item>
        </el-breadcrumb>

        <el-table :data="list" stripe :show-header="false" v-loading="loading" style="width: 100%;font-size: large">
            <el-table-column prop="Name" />
            <el-table-column prop="CreatedOn" width="200"/>
            <el-table-column fixed="right" :width="100">
                <template slot-scope="scope">
                    <el-button type="text" size="large" @click="onClick(scope.row.DocumentUrl)">下载</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
export default {
    name: 'about',
    data() {
        return {
            loading: false,
            list: []
        }
    },
    mounted() {
        this.$API.GetResourceList('Document', { Filter: 'Disabled="false"', Sort: 'Seq' }, resp =>{
            this.list = resp.Data.Items
        })
    },
    methods: {
        onClick(url){
            window.open(url)
        }
    }
}
</script>

<style lang="scss">
::-webkit-scrollbar {
    width: 0 !important;
}
::-webkit-scrollbar {
    width: 0 !important;height: 0;
}

.page-download{
    margin: 0 auto;
    width:1200px;

    background-color: #FFFFFF;
    padding: 20px;

    .breadcrumb-class{
        //margin-top: 20px;
        margin-bottom: 30px;
        font-size: 20px
    }
}
</style>
