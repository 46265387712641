<template>
    <div class="page-scope-list">
        <el-breadcrumb class="breadcrumb-class">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>服务范围</el-breadcrumb-item>
        </el-breadcrumb>
<!--        <div style="margin-left: 23%">-->
<!--            <el-image src="https://erp.miaosou.club/files/zhizhi/file/scope_1.png" />-->
<!--            <el-image src="https://erp.miaosou.club/files/zhizhi/file/scope_2.png" />-->
<!--            <el-image src="https://erp.miaosou.club/files/zhizhi/file/scope_3.png" />-->
<!--        </div>-->
        <div class="policy-card-list" v-for="(item,index) in list" :key="index" >
            <div style="display: flex;">
                <el-image style="width: 400px; height: 200px" :src="item.ImageUrl" />
                <div style="padding: 30px">
                    <el-link style="padding-bottom: 50px;font-size: 30px;font-weight: bold" @click="onClickNews(item)">{{item.Name}}</el-link>
                    <div>时间：{{item.BizDateText}}</div>
                    <div>作者：{{item.Author}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    //服务范围-列表
    name: 'scope-list',
    data() {
        return {
            list: []
        }
    },
    mounted() {
        this.$API.GetResourceList('Article', { Filter: "GroupID==5 and Status==\"C\"", Sort: 'Seq,BizDate desc' }, resp =>{
            this.list = resp.Data.Items
        })
    },
    methods: {
        onClickNews(data){
            this.$router.push({ name: "news", params: { data: data } })
        }
    }
}
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
    width: 0 !important;
}
::-webkit-scrollbar {
    width: 0 !important;height: 0;
}

.page-scope-list {
    margin: 0 auto;
    width: 1200px;

    background-color: #FFFFFF;
    padding: 20px;

    .breadcrumb-class {
        //margin-top: 20px;
        margin-bottom: 30px;
        font-size: 20px
    }

    .policy-card-list {
        margin-bottom: 20px;

        box-shadow: 0 0 5px #c1c1c1;
    }
}

</style>


